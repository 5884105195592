<script>
import BVForm from "@/components/BVForm/BVForm.vue";
import ItemList from "@/components/ItemList/ItemList.vue";
const Swal = require("sweetalert2");

export default {
  name: "InformacionVacante",
  components: {
    BVForm,
    ItemList,
  },
  data() {
    return {
      vacanteEditable: null,
      vacante: null,
      isDataWorkspace: false,
    };
  },
  async mounted() {
    this.$isLoading(true);
    this.vacanteEditable = await this.$store.dispatch("getEditarVacante");
    await this.insertDataInSchema();
    this.$isLoading(false);
  },
  computed: {
    vacanteId() {
      return this.$route.params.id;
    },
  },
  methods: {
    watcherForm({ name, value, idx }) {
      if (this.isDataWorkspace) return;

      if (name == "paisId") {
        this.vacanteEditable[idx + 1].value = null;
        this.vacanteEditable[idx + 2].value = null;
        this.vacanteEditable[idx + 1].options =
          this.getDepartamentosByPaisId(value);
      } else if (name == "departamentoId") {
        this.vacanteEditable[idx + 1].value = null;
        this.vacanteEditable[idx + 1].options =
          this.getMunicipiosByDepartamentoId(value);
      }
    },
    getDepartamentosByPaisId(paisId) {
      return this.$store.getters
        .getSourceData("departamentos")
        .filter((item) => item.paisId == paisId);
    },
    getMunicipiosByDepartamentoId(departamentoId) {
      return this.$store.getters
        .getSourceData("municipios")
        .filter((item) => item.departamentoPaisId == departamentoId);
    },
    async insertDataInSchema() {
      const response = await this.$store.getters.fetchGet({
        path: `vacante/${this.vacanteId}`,
      });
      const data = await response.json();
      this.vacante = data;

      this.vacanteEditable.forEach(async (item) => {
        if (item.type == "date") {
          data[item.name] = data[item.name].split("T")[0];
        }
        item.value = data[item.name];
        if (item.name == "paisId") {
          item.options = this.$store.getters.getSourceData("paises");
        } else if (item.name == "departamentoId") {
          item.options = this.getDepartamentosByPaisId(data.paisId);
        } else if (item.name == "municipioId") {
          item.options = this.getMunicipiosByDepartamentoId(
            data.departamentoId
          );
        } else if (item.name == "jornadaLaboralId") {
          item.options = await this.$store.dispatch("getJornadasLaborales");
        }
      });

      this.isDataWorkspace = true;
    },
    actualizarVacante(self) {
      self.loader = true;
      this.$store.getters
        .fetchPut({ path: `vacante/${this.vacanteId}`, data: self.data })
        .then((response) => {
          if (!response.ok) throw new Error(response.status);
          return response.json();
        })
        .then(() => {
          Swal.fire({
            title: "Exito!",
            text: "Se ha guardado correctamente.",
            icon: "success",
            confirmButtonText: "continuar en la vista",
            showCancelButton: true,
            cancelButtonText: "Regresar",
          }).then((result) => {
            if (result.isConfirmed) {
              // pass
            } else {
              this.$router.go(-1);
            }
          });
        })
        .cath((error) => {
          Swal.fire({
            title: "Error!",
            text: error.message,
            icon: "error",
            confirmButtonText: "Continuar",
          });
        })
        .finally(() => {
          self.loader = false;
        });
    },
  },
};
</script>

<template>
  <HXContentCard
    title="Actualizar vacante"
    :toBack="{ name: 'AdministradorDeVacantes' }"
  >
    <div class="p-4">
      <h3>
        <strong>Información del cargo</strong>
      </h3>
      <div class="my-2 grid-container">
        <ItemList
          title="Empresa"
          :value="vacante.cargo.sucursal.empresa.nombre"
        >
        </ItemList>
        <ItemList title="Nombre del cargo" :value="vacante.cargo.nombre">
        </ItemList>
        <ItemList title="Sucursal" :value="vacante.cargo.sucursal.descripcion">
        </ItemList>
        <ItemList title="Área" :value="vacante.cargo.area.descripcion">
        </ItemList>
      </div>
      <div class="d-flex justify-content-end">
        <router-link
          class="btn btn-danger"
          :to="{ name: 'Cargos_editar', params: { id: vacante.cargo.id } }"
        >
          <i class="fas fa-pen"></i>
          Editar cargo
        </router-link>
      </div>
    </div>
    <br />
    <hr />
    <BVForm
      class="p-4"
      title="Información de la vacante"
      :callBackSubmit="actualizarVacante"
      :observer="['paisId', 'departamentoId']"
      @watcher="watcherForm"
      :schema="vacanteEditable"
    >
      <template #text-submit> Actualizar </template>
    </BVForm>
  </HXContentCard>
</template>

<style scoped>
.grid-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 20px;
  padding: 20px;
}
</style>
